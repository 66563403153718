/* Import the font family */
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@200;400&family=Fjalla+One&family=IBM+Plex+Sans&family=Jost:wght@300&family=Mulish:wght@200&display=swap');

/* Update the CSS for the inquiry page by adding a background image and modifiying its attributes */
body {
  background-image: url('../src/components/images/la-bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgb(0, 0, 0);
  background-size: cover;
  background-attachment: fixed;
  font-family: 'Jost', 'Anuphan', 'Cambria', 'Roboto', sans-serif;
  padding-bottom: 5%;
  position: relative;
}
@media (max-width: 767px) {
  body::before {
    content: "";
    position: fixed; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; 

    background-image: url('../src/components/images/la-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(0, 0, 0); 
  }

  body {
    background: none; /* remove the background from the body */
  }
}

html {
  overflow-y: scroll;
}

/* Update the CSS for the headers to have them all padded from the top, centered, and some additional changes */
h1 {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

/*
 * Modifying buttons, labels, and additional fields on the Form below
 */

/* Update the CSS for the forms to have them all be padded 32.5% from the left */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Update the CSS for the labels in the divs in the form to make them look more appealing */
form > label {
  font-family: Arial, sans-serif;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.08rem;
  margin-bottom: 5px;
  
}
input::placeholder, textarea::placeholder {
  color: #888888d2;  /* Change to the color you desire */
  font-family: Arial, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.09rem;
}

@media (max-width: 400px) {
  form > input,
  form > select,
  form > textarea,
  .file {
    width: 75%;
  }
}


@media (min-width: 400px) {
  form > input,
  form > select,
  form > textarea,
  .file {
    width: 300px;
  }
}
/* Update the CSS for the inputs, textarea, and selects in the divs which are in the form to make fields look more appealing */
form > input,
form > select,
form > textarea{
  
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1a1515;
  box-shadow: 0 0 3px rgba(0, 0, 0, 3);
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: rgb(240, 232, 232);
}

form > textarea{
  resize: none;
}
/* Update the CSS for the inputs, textarea, and selects for when they are being focused on */
form > input:focus,
form > textarea:focus,
form > select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Update the CSS for the textarea to have it set to a height of 150 pixels */
form > textarea {
  height: 150px;
  font-family: Arial, sans-serif;
}

/*
Update the CSS for the input of type file to hide the display feature */
form > input[type="file"] {
  margin-left: 10px;
  width: 120px;
}

/* Update the CSS for the label of type image to add additional CSS features to make it look better */
form > label[for="image"] {
  background-color: #6d2d2d;
  color: #ffffff;
  letter-spacing: 0.02rem;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

/* Update the CSS for the label of type image to change the background color when being hovered over */
form > label[for="image"]:hover {
  background-color: #f36d6d;
  transition: background-color 0.2s ease;
}

/* Update the CSS for the label of type image to add some additional CSS features before the label is being hovered over */
form > label[for="image"]::before {
  font-size: 1.2rem;
  font-weight: bold;
}

form > input[type="file"] {
  display: none;
}

/* Update the CSS for the button of type submit to add some additional CSS features to make it look appealing */
div button[type="submit"], div button[type="button"] {
  display:inline-block;
  padding:10px 20px;
  margin:0.1em;
  border-radius: 5px;
  border:0.20em solid #CCCCCC;
  box-sizing: border-box;
  text-decoration:none;
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  font-weight:500;
  letter-spacing: 0.09rem;
  color:#000000;
  background-color:#cccccc;
  text-align:center;
  position:relative;
}

/* Update the CSS for the button of type submit/button to change the background color when being hovered and pressed */
form > button[type="submit"]:hover,
form > button[type="button"]:hover{
  border-color:#7a7a7a;
}

form > button[type="submit"]:active,
form > button[type="button"]:active{
  background-color:#999999;
}

div button[type="submit"]:hover,
div button[type="button"]:hover{
  border-color: #7a7a7a;
}

div button[type="submit"]:active,
div button[type="button"]:active{
  background-color:#999999;
}


.alert {
  background-color: green;
  color: #fff;
  display: block;
  padding: 10px;
  margin-top: 45px;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: -50px;
  text-align: center;
}

@media (min-width: 1192px) {
  .alert {
    margin-top: 0;
    margin-bottom: -20px;
  }
}

.recaptcha {
  transform:scale(1.06);
  -webkit-transform:scale(1.06);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
  margin-left: -15px;
}

@media (max-width: 400px) {
  .recaptcha {
    transform:scale(80%);
    -webkit-transform:scale(80%);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    margin-left: 20%;
  }
}