/* Modal Overlay */
.modal {
  display: flex; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1;
  justify-content: center;  
  align-items: center;     
}

/* Modal Content Box */
.modal-content {
  background-color: #fff;
  border-radius: 10px;
  width: 70%;
  max-width: 800px;        
  max-height: 85%;
  overflow-y: auto;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  padding: 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.modal-left, .modal-right {
  flex: 1;
}

/* Headers */
.modal-content h2 {
  font-size: 24px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

/* Paragraphs */
.modal-content p {
  font-size: 16px;
  margin-bottom: 10px;
}

/* Input field */
.modal-left input[type="text"] {
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.modal-left input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

/* Buttons */
.modal-left button, .modal-left a.send-email-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-left button.Mark-In-Progress-button {
  background-color: #007bff;
  color: white;
  font-family: Arial, sans-serif;
}

.modal-left button.Mark-In-Progress-button:hover {
  background-color: #0056b3;
}

.modal-left a.send-email-button {
  display: inline-block;
  background-color: #2e8a3f;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-family: Arial, sans-serif;
}

.modal-left a.send-email-button:hover {
  background-color: #08751a;
}

.modal-content button.delete-button {
  background: #9e0404;
  color: white;
}

.modal-content button.delete-button:hover {
  background: #ff3333;
}
.completed-btn{
  background-color: rgb(26, 124, 26);
  color: white;
}
.completed-btn:hover{
  background-color: rgb(22, 194, 22);
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 26px;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s;
}

.close-button:hover {
  color: #000;
}

/* Image Styling */
.modal-right img {
  max-width: 100%;
  min-width: 300px; /* Add this line */
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}


.modal-right p {
  margin-bottom: 15px;
}
