@import url('https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');

/* .contact {
    width: 100%;
    max-width: 10000px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */


.contact_header {
  margin-bottom: 40px;
  font-size: 3em;
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  color: #ffffff;
}


.contact{
  min-height: 60vh;
  text-align: center;
}

.messageText{
  color: rgba(255, 255, 255, 0.925);
  background-color: rgba(38, 39, 38, 0.678);
  padding: 10px;
  border-radius: 5px;
  margin-top: -30px;
  margin-bottom: 40px;
  width: 300px;
  text-align: center;
  border: 0.5px solid rgba(218, 206, 206, 0.856);
}

@media (max-width: 400px) {
  .messageText {
    width: 75%;
  }
}