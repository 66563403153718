.user-pass-container {
  padding-top: 20px; 
  /*add padding to move it down  */ 
}
/*
.buttons {
  display: flex;
  justify-content: left;
  gap: 10px;
}
*/

.admin-page-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
}
.login_header{
  margin-top: 70px;
  font-size: 3em;
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  color: #ffffff;
}

