.Dashboard *, .Dashboard *:before, .Dashboard *:after {
  box-sizing: border-box;
}

.landing {
  width: 75%;
  margin: 0 auto;
  min-height: 80vh; 
}

.file {
  background-color: #6d2d2d;
  color: #ffffff;
  letter-spacing: 0.02rem;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}
.btn-group, table{
  max-width: 800px;
  margin: 0 auto;
}

table {
  width: 100%;
  margin-bottom: 1%;
  padding: 10px;
  background-color: rgba(43, 31, 31, 0.486);
  align-items: center;

}
.deleteAll-btns {
  display: flex;
  flex-wrap: nowrap; 
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 30px;
}

.deleteAll-btns > button {
  color: rgba(255, 255, 255, 0.966);
  background-color: rgba(226, 4, 4, 0.664);
  border: 1px solid rgba(0, 0, 0, 0.233);
  margin: 10px;
  border-radius: 15px;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  cursor: pointer;
  width: 100px; 
  height: 30px; 
  font-size: 12px;
}

.deleteAll-btns > button:hover {
  background-color: rgb(155, 5, 5);
  border: 1px solid rgba(255, 255, 255, 0.815);
}
th {
  border: 1px solid;
  text-align: left;
  padding: 8px;
  background-color: transparent;
  color: white;
}
.open-btn, .x-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.table-title{
  text-wrap: nowrap;
}
.fnameLname{
  max-width: 40px;
}
.open-btn {
  background-color: #4CAF50;
  color: white;
  margin-left: 2px;
}
.open-btn:hover {
  background-color: #228f27;
}
.x-btn{
    float: right;
    margin-left: 5px;
    margin-right: 2px;
    color: white;
    background-color: rgb(180, 0, 0);
}
.x-btn:hover {
  background-color: #f11e0f;
}
.action-buttons {
  display: flex;        
  justify-content: space-between; 
  flex-wrap: nowrap;     
}

td {
  border: 1px solid;
  text-align: left;
  background-color: transparent;
  color: white;
}

.bottom-container-dash {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn-group3{
  margin-top: 3px;
}

.btn-group2, .btn-group3 {
  display: flex;
  justify-content: center;
  gap: 7px; 
  width: 100%; 
}


.btn-group{
margin-bottom: 50px;
}

.inquire-btn {
  margin-top: 3px;
  background-color: rgba(0, 0, 0, 0.493);
    border: 1.5px solid #000000;
    color: #ffffff;
    text-decoration: underline; 
    cursor: pointer;
    float: left;
    padding: 10px 16px;
    font-size: 18px;
}
.admin-page-title{
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  margin-top: 50px;
}

.inquire-btn:hover, .active {
  color: white;
  cursor: pointer;
  background-color: #000000fd;
}

.inquire-btn.button-selected {
  background-color: rgb(129, 129, 129);
  color: white;
  cursor: auto;
}

.inquire-btn:not(:last-child) {
  border-right: none; 
}

.logout-btn{
  background-color: black;
  color: white;
  padding: 0.3em 1.5em;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid white;
  width: 10em;
  margin-top: 3px;
  
}
.logout-btn:hover{
  background-color: rgb(129, 129, 129);
}
.grid-container {
  max-width: 1320px; 
  margin: 0 auto;
}
.portfolioEdit-btn{
  margin-top: 10px;
  background-color: rgb(1, 116, 49);
  color: white;
  padding: 0.3em 1.5em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid white;
  width: 10em;
  border-radius: 15px;
}
.portfolioEdit-btn:hover{
  background-color: rgb(45, 194, 70);
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.portfolioGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 3px;
}

.portfolioImage img {
  width: 120px;  
  height: 120px;
  object-fit: cover;
  border-radius: 5px;  
}

.portfolioImage img:hover {
  transform: scale(1.3);
  cursor: pointer;
}


.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center; 
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
}
@media(max-width: 1192px){
  .admin-page-title{
    margin-top: 60px;
  }
}

@media (max-width: 862px) {

.landing {
   width: 98%;
  }

.inquire-btn {
    border: 1px solid #000000;
    font-size: 12px;
    padding: 10px 15px;  
}
  
.active, .inquire-btn:hover {
    border: 1px solid #000000;
}

.logout-btn{
  font-size: 12px;
}

.portfolioEdit-btn{
  font-size: 12px;
}
.table table {
  font-size: 14px;
  padding: 5px;
}
.welcome-message{
  color: white;
  padding: 10px 20px;        
  font-size: 1.1rem;
  opacity: 1;
  transition: opacity 3s; 
}  
.portfolioGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 3fr));
  column-gap: -5px;
}
@media(max-width: 500px){
  .portfolioImage img:hover {
    transform: scale(1.1);
  }
}
}
@media (max-width: 359px) {
  .portfolioGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 437px) {
  .deleteAll-btns > button {
    max-width: 80px;
    height: 34px;
  }
}

@media (max-width: 415px) {
  .deleteAll-btns > button {
    max-width: 80px;
    height: 34px;
  }
  
}