.inquiry {
    width: 100%;
    margin: 0 auto;
}

.submit-btn{
    margin-top: 10px;
}

.inquiry_header {
  font-size: 3em;
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  color: #ffffff;
  margin-bottom: 30px;
}

.text-content1 {
  color: rgb(255, 255, 255);
  font-size: 1em;
  letter-spacing: -0.04em;
  text-align: center;
  background-color: rgb(107, 1, 1);
  width: 75%;
  min-width: 600px;
  padding: 10px;
}

