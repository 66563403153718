

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.headerContainer{
  display: flex;
  justify-content: space-around;
}
.discountText{
  color: rgba(255, 255, 255, 0.925);
  background-color: rgba(38, 39, 38, 0.678);
  padding: 1% 1.5%;
  border-radius: 5px;
  margin-top: 35px;
  margin-left: 10px;
  margin-bottom: 30px;
  max-width: 200px;
  border: 0.5px solid rgba(218, 206, 206, 0.856);
}
.spacer{
  width: 300px;
}

.servicesTitle {
  font-size: 3em;
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  color: #ffffff;
  margin: 0;
  margin-top: 30px;
}
.services-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-top: -40px;
  margin-left: 30px;
  margin-right: 30px;
}
.services-container a {
  text-decoration: none;
}
.services-item{
  display: flex;
  background-color: #160c088a;
  padding: 5px 10px 5px 6px;
  -webkit-box-shadow: 10px 12px 11px -2px rgba(0,0,0,0.46);
  -moz-box-shadow: 10px 12px 11px -2px rgba(0,0,0,0.46);
  box-shadow: 3px 3px 4px 1px rgba(0, 0, 0, 0.349);
  border-radius: 10px;
  max-width: 600px;
}
.itemImg{
  /*max-width: auto; */
  max-height: 250px;
  object-fit: cover;
  display: block; /* to remove the bottom spacing */
  border-radius: 10px;
  width: 200px;
  height: 200px;
}
.itemTitle {
  font-weight: lighter;
  font-size: 30px;
  font-weight:200;
  margin: 5px 0px 0px 5px;
  min-width: 100%;
  text-shadow: 1px 1px 5px #e6ddddaf;
}
.itemText > p{
  min-width: 70%;
  color: white;
  margin-top: 10px;
  margin-left: 10px;
}
@media(max-width: 1300px){
  .itemImg {
    min-width: 200px;
    width: 200px;
    height: 200px;
  }
}
@media(max-width: 1192px){
  .spacer{
    width: 0px;
  }
  .services-container {
    margin-left: 30px;
    margin-right: 30px;
  }
  .servicesTitle{
    margin-top: 80px;
  }
  .discountText{
    padding: 1.3% 1.5%;
    margin-top: 80px;
    margin-bottom: 20px;
  }
}

/*
@media (max-width: 852px) {
  .itemTitle {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .services-container{
    grid-gap: 15px;
    margin-left: 30px;
    margin-right: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  .services-item{
    padding: 0px 0px 0px 0px;
  }
}

@media (max-width: 740px) {
  .services-container{
    margin-top: 0px;
  }
  .page-container {
    margin-top: 10px;
  }
  .discountText{
    margin-bottom: 20px;
  }
  .itemImg{
    max-width: 100px;
    height: 200px;
    object-fit: cover;
    display: block;
  }
  .headerContainer{
    margin-bottom: 20px;
  }
  .itemDescription{
    hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1.1;
  }
  .services-container{
    gap: 20px;
  }
  .services-item{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

}
*/

@media (max-width: 852px) {
  .services-container{
    grid-gap: 15px;
    margin-left: 30px;
    margin-right: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  .itemImg{
    max-width: 50%; /* or 100% if you want it to take the full width of the container */
    height: 200px;
    object-fit: cover;
    display: block; /* to remove the bottom spacing */
  }
  .itemDescription{
    
    font-size: 0.9rem;
  }
  .itemTitle{
    font-size: 1.3rem;
    line-height: 0.9;
    
  }
  .itemText {
    width: 200px;
  }
  .itemText > .itemDescription{
    color: rgba(255, 255, 255, 0.918);
  }
}
@media (max-width: 531px) {
  .itemDescription{
    
    max-width: 90%;
  }

}
@media (max-width: 513px) {
  .itemDescription{
    max-width: 80%;
  }

}
@media (max-width: 481px) {
  .itemDescription{
    width: 105px;
  }
  .services-container{
    gap: 20px;
  }
  .itemImg {
    min-width: 0;
  }
}
@media (max-width: 426px) {
  .itemDescription{
    width: unset;
    font-size: 0.65rem;
  }
  .itemImg{
    max-height: 150px;
  }
  .itemTitle{
    font-size: 1.1rem;
  }
}
@media (max-width: 350px){
  .services-container{
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  
}