.pagination-text {
  color: #ffffff; /* Change to your desired font color */
  margin-top: 10px;
}

.pagination-button {
  background-color: #333; /* Change to your desired button background color */
  color: #FFF;   /* Change to your desired button font color */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 30px 5px; /* Optional: Added for spacing */
  border-radius: 3px; /* Optional: Rounded corners */
}

.pagination-button:hover {
  background-color: #555; /* Optional: Change for hover effect */
}

.portfolio-images {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  gap: 8px;
  width: fit-content; /* This will make the grid as wide as its content */
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack children vertically */
}


.galleryTitle{
  font-size: 3em;
  font-size: 3em;
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  color: #ffffff;
  margin-top: 50px;
  
}
.appointment-btn{
  background-color: rgba(0, 0, 0, 0.329);
  color: white;
  padding: 0.7em 1.9em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  white-space: nowrap;
  width: auto;
  text-decoration: underline;
}
.appointment-btn:hover{
  background-color: rgb(0, 0, 0);
}
.right-arrow {
  right: 15%;
}

.left-arrow {
  left: 15%;
}

/* Styles from Box.css */
.box {
  border-radius: 0.5rem;
  max-width: 240px;
  max-height: 240px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.box img:hover {
  cursor: pointer;
}

.box img {
  width: 240px;
  height: 240px;
  object-fit: cover;
}
@media (max-width: 1237px){
  .portfolio-images {
    grid-template-columns: repeat(4, 1fr); /* 3 columns */
  }
}
@media (max-width: 978px) {
  .portfolio-images {
    grid-template-columns: repeat(3, 1fr);
  }
  .right-arrow {
    right: 3%;
  }
  
  .left-arrow {
    left: 3%;
  }
}

@media (max-width: 768px) {
  .portfolio-images {
      grid-template-columns: repeat(2, 1fr);
  }
  
}
@media (max-width: 748px) {
  .galleryTitle{
    margin-top: 80px;
  }
}
@media (max-width: 531px) {
  .galleryTitle{
    line-height: 1.1;
  }
}
@media (max-width: 478px) {
  .box {
    max-width: 200px;
    max-height: 200px;
  }
}
@media (max-width: 415px) {
  .box {
    max-width: 150px;
    max-height: 150px;
  }

}
/* Modal styling */
.modal-portfolio {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image {
  max-width: 80%;
  max-height: 80%;
}

.arrow {
  position: absolute;
  top: 50%;
  font-size: 3em;
  color: rgba(255, 255, 255, 0.726);
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
  transform: scaleX(0.6);
  padding: 5px;
}

.appointment-btn{
  background-color: rgba(0, 0, 0, 0.329);
  color: white;
  padding: 0.7em 1.9em;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgb(255, 255, 255);
  white-space: nowrap;
  width: auto;
  text-decoration: underline;
}
.appointment-btn:hover{
  background-color: rgb(0, 0, 0);
}
.right-arrow {
  right: 15%;
}

.left-arrow {
  left: 15%;
}

/* Styles from Box.css */
.box {
  border-radius: 0.5rem;
  max-width: 240px;
  max-height: 240px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.box img:hover {
  cursor: pointer;
}

.box img {
  width: 240px;
  height: 240px;
  object-fit: cover;
}
@media (max-width: 1237px){
  .portfolio-images {
    grid-template-columns: repeat(4, 1fr); /* 3 columns */
  }
}
@media (max-width: 978px) {
  .portfolio-images {
    grid-template-columns: repeat(3, 1fr);
  }
  .right-arrow {
    right: 3%;
  }
  
  .left-arrow {
    left: 3%;
  }
}

@media (max-width: 768px) {
  .portfolio-images {
      grid-template-columns: repeat(2, 1fr);
  }
  
}
@media (max-width: 748px) {
  .galleryTitle{
    margin-top: 80px;
  }
}
@media (max-width: 531px) {
  .galleryTitle{
    line-height: 1.1;
  }
  .right-arrow {
    right: 0.5%;
  }
  
  .left-arrow {
    left: 0.5%;
  }
}
@media (max-width: 478px) {
  .box {
    max-width: 200px;
    max-height: 200px;
  }
}
@media (max-width: 415px) {
  .box {
    max-width: 150px;
    max-height: 150px;
  }

}
/* Modal styling */
.selected-image-modal-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.788);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image {
  max-width: 80%;
  max-height: 80%;
}

.arrow {
  position: absolute;
  top: 47%;
  font-size: 3em;
  color: rgba(255, 255, 255, 0.726);
  cursor: pointer;
  user-select: none;
  transform: translateY(-50%);
  transform: scaleX(0.6);
  padding: 5px;
}