
.AboutJulioJimenez *, .AboutJulioJimenez *:before, .AboutJulioJimenez *:after {
    box-sizing: border-box;
  }
  
.about_header{
  font-size: 3em;
  font-family: 'Oleo Script', 'Jost', sans-serif, Arial;
  font-weight: lighter;
  color: #ffffff;
}
.top-container{
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 2em;
    padding: 4%;
}
.top-container > .img-container > img{
    min-width: 500px;
    max-width: 90%;
    height: auto;
    -webkit-box-shadow: 10px 12px 11px -2px rgba(0, 0, 0, 0.281);
    -moz-box-shadow: 10px 12px 11px -2px rgba(0, 0, 0, 0.267);
    box-shadow: 10px 12px 11px -2px rgba(0, 0, 0, 0.192);
    filter: brightness(0.9);
    border-radius: 10px;
}

.text-box{
    background-color: #271d1996;
    padding: 2.3em 0em 2.3em 2em;
    -webkit-box-shadow: 10px 12px 11px -2px rgba(0,0,0,0.46);
    -moz-box-shadow: 10px 12px 11px -2px rgba(0,0,0,0.46);
    box-shadow: 5px 13px 15px 5px rgba(0, 0, 0, 0.349);
    border-radius: 10px;
}
.text-box > .text-content{
    color: rgb(255, 255, 255);
    font-size: 1.5em;
    letter-spacing: -0.04em;
    width: 75%;
    min-width: 300px;
}
.bottom-container{
    background-color: rgba(15, 12, 9, 0.425);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Anuphan', 'Jost', 'Cambria', 'Roboto', sans-serif;
}
.info{
    font-size: 1.6rem;
    color: rgb(221, 221, 221);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.appointment {
    margin-top: 0px;
}
/* button */
.about-btn{
    display: inline-block;
    margin: 1em 0 4em 0;
    font-size: 1rem;
    text-decoration: none;
    padding: 10px 30px;
    background-color: #ada18a;
    color: #ffffff;
    border-radius: 25px;
    font-weight: bold;
    border: none;
    letter-spacing: 4px;
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer;
    box-shadow: 0 0 2px #f2fafa,
                  0 0 15px #dbe2e2;
    -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
    background-image: linear-gradient(to right, #0e1a20 0%, #413f33  51%, #000203  100%);
  }
  .contact-btn{
    display: inline-block;
    margin: 0em 0 4em 0;
    font-size: 0.8rem;
    text-decoration: none;
    padding: 10px 30px;
    background-color: #ada18a;
    color: #ffffff;
    border-radius: 25px;
    font-weight: bold;
    border: none;
    letter-spacing: 4px;
    overflow: hidden;
    transition: 0.5s;
    cursor: pointer;
    box-shadow: 0 0 2px #f2fafa,
                  0 0 15px #dbe2e2;
    background-image: linear-gradient(to right, #0e1a20 0%, #413f33  51%, #000203  100%);
  }
  .contact-btn:hover{
    background: #ddf1a4;
    color: #050801;
    box-shadow: 0 0 5px #e0bc5a,
                0 0 25px #e0bc5a,
                0 0 35px #e0bc5a;
     -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
}
  .about-btn:hover{
      background: #ddf1a4;
      color: #050801;
      box-shadow: 0 0 5px #e0bc5a,
                  0 0 25px #e0bc5a,
                  0 0 35px #e0bc5a;
       -webkit-box-reflect:below 1px linear-gradient(transparent, #0005);
  }
@media (max-width: 1192px) {
    .top-container{
        gap: 7%;
        margin-top: 30px;
        text-align:center;
    }
    .text-box{
        margin-top: 30px;
        margin-bottom: 20px;
        background-color: rgba(0, 0, 0, 0.637);
    }
    .text-box > .text-content {
        width: auto;
        min-width: 0;
        margin-right: 15px;
    }

}
@media (max-width: 968px) {
    .top-container{
        flex-wrap: wrap;
    }
    .top-container > .img-container > img{
        max-width: 400px;
        height: auto;
    }
    .top-container > .text-box{
        width: 500px;
    }
    .text-box > .text-content{
       min-width: 300px;
    }
}

 /* Style the login button */
 .lounge-button {
     font-size: 1em;
     font-weight: bold;
     border: none;
     cursor: pointer;
     background-color: #ffffff00;
     color: rgba(59, 49, 49, 0.795);

 }

 .login {
     background-color: #000;
     display: flex;
     justify-content: right;
     margin-top: -10px;

 }

 @media (max-width: 670px) {
    .top-container > .img-container > img{
        margin-top: 15px;
        margin-bottom: -15px;
    }
    
 }
 
 @media (max-width: 545px){

    .top-container > .img-container > img{
        min-width: 350px;
        max-width: 200px;
    }
    .text-box{
       max-width: 350px;
       padding: 20px 20px;
       margin-bottom: 5px;
    }
    .text-box > .text-content{
        font-size: 17px;
        text-align: left;
        max-width: 350px;
        margin-left: 10px;
    }
 }
 @media(max-width: 407px){
    .text-box{
        padding: 15px 7px;
     }
     
 }
 @media (max-width: 367px){
    .top-container > .img-container > img{
        min-width: 300px;
    }
    .text-box > .text-content{
       font-size: 14px;
       width: 290px;
       min-width: 100px;
    }
    .text-box{
        max-width: 305px;
    }

 }


