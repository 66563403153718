/* Style for the payment button */
.payment-button {
    background-color: #1a1515; /* PayPal blue color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 100px;
    font-size: 1.3rem;
}

.payment-button:hover {
    opacity: 0.8;
}
.payment-portal {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
}

.box-payment-portals img {
    width: 330px;
    height: auto;
    display: block;
    margin: 0 auto; /* center the image */
    border-radius: 20px;
}

/* Style for the payment logo */
.payment-logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}
.box {
    width: 345px;
}

.continue-pay {
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background: #169BD7;
    width: 300px;
    height: 80px;
    font-size: 1.3rem;
    border-radius: 10px;
}

/* For mobile responsiveness */
@media (max-width: 437.5px) { /* adjust the breakpoint as needed */
    .payment-button, .box {
        width: 75%; /* or some other relative value */
    }

    .box-payment {
        width: 75%;
    }

    .box-payment-portals img {
        width: 100%;
    }

}